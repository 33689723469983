import React from 'react'
import { config } from 'react-awesome-styled-grid'
import Media from 'react-media'
import { isEmpty } from 'lodash'
import Link from 'next/link'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Category from '~/components/Category'
import { getFreshDate } from '~/helpers/utils'

/**
 * FeedItem Component
 */

const FeedItem = ({ item, onPress, showCategory, isMobile }) => {
  const { id, images, title, slug, category, createdAt, excerpt } = item

  const shouldShowCategory = showCategory && !isEmpty(category)

  return (
    <Media
      queries={{
        small: '(max-width: 768px)',
      }}
      defaultMatches={{ small: isMobile }}
    >
      {matches => (
        <StyledLink>
          <Container onClick={onPress}>
            {!matches.small && (
              <Link key={id} href="/[slug]" as={`/${slug}`} passHref>
                <a>
                  <Image src={images.medium} />
                </a>
              </Link>
            )}
            <Info>
              {shouldShowCategory && <Category category={category} />}
              <Link key={id} href="/[slug]" as={`/${slug}`} passHref>
                <InfoText>
                  <div>
                    <TitleText>{title}</TitleText>
                    <ExcerptText>{excerpt}</ExcerptText>
                  </div>
                  <SecondaryText>{getFreshDate(createdAt)}</SecondaryText>
                </InfoText>
              </Link>
            </Info>
          </Container>
        </StyledLink>
      )}
    </Media>
  )
}

/**
 * PropTypes
 */

FeedItem.propTypes = {
  item: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  onPress: PropTypes.func,
  showCategory: PropTypes.bool,
}

FeedItem.defaultProps = {
  onPress: null,
  showCategory: true,
}

/**
 * Styled Components
 */

const StyledLink = styled.div`
  text-decoration: none;
  width: 100%;
`

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin: 15px 0;
  max-width: 800px;
`

const Info = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`

const InfoText = styled.a`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
`

const Image = styled.div`
  position: relative;
  box-sizing: border-box;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center center;
  cursor: pointer;
  margin-right: 20px;
  width: 150px;
  height: 90px;

  ${props => config(props).media.lg`
    width: 240px;
    height: 144px;
  `}
`

const TitleText = styled.h1`
  display: block;
  margin: 0 0 10px 0;
  font-size: 18px;
  color: ${props => props.theme.colors.text};
  cursor: pointer;
`

const SecondaryText = styled.p`
  text-decoration: none;
  font-size: 16px;
  color: ${props => props.theme.colors.secondaryText};
  margin: 0;
`

const ExcerptText = styled(SecondaryText)`
  margin-bottom: 10px;
`

/**
 * Exports
 */

export default FeedItem
