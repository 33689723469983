import React from 'react'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import SocialMediaStrip from '~/components/SocialMediaStrip'
import { Routes } from '~/helpers/constants'

/**
 * DrawerMenu
 */

const DrawerMenu = ({ isOpen = false, onClose }) => (
  <Container isOpen={isOpen}>
    <CloseButton aria-label="Cerrar menú" onClick={onClose}>
      <FontAwesomeIcon size="2x" icon={faTimes} />
    </CloseButton>
    <Link href={`${Routes.CATEGORY}/[category]`} as={`${Routes.CATEGORY}/noticias`} passHref>
      <NavBarItem onClick={onClose}>Noticias</NavBarItem>
    </Link>
    <Link href={`${Routes.CATEGORY}/[category]`} as={`${Routes.CATEGORY}/musica`} passHref>
      <NavBarItem onClick={onClose}>Música</NavBarItem>
    </Link>
    <Link href={`${Routes.CATEGORY}/[category]`} as={`${Routes.CATEGORY}/articulos`} passHref>
      <NavBarItem onClick={onClose}>Artículos</NavBarItem>
    </Link>
    <Link href={`${Routes.CATEGORY}/[category]`} as={`${Routes.CATEGORY}/tecnologia`} passHref>
      <NavBarItem onClick={onClose}>Tecnología</NavBarItem>
    </Link>
    <Link href={Routes.TOP_100} passHref>
      <NavBarItem onClick={onClose}>Top 100</NavBarItem>
    </Link>
    <NavBarItem onClick={onClose} href="https://www.otherpeople.club" target="_blank">
      Merch
    </NavBarItem>
    <NavBarItem onClick={onClose} href="https://issuu.com/djmagla" target="_blank">
      Revista
    </NavBarItem>
    <SocialMediaContainer>
      <SocialMediaStrip inverted />
    </SocialMediaContainer>
    <TextContainer>
      <Link href={Routes.ABOUT} passHref>
        <Text>Quiénes Somos</Text>
      </Link>
      <Link href={Routes.ADVERTISING} passHref>
        <Text>Publicidad</Text>
      </Link>
      <Link href={Routes.JOBS} passHref>
        <Text>Trabajos</Text>
      </Link>
      <Link href={Routes.TERMS} passHref>
        <Text>Términos y condiciones</Text>
      </Link>
      <Link href={Routes.PRIVACY_POLICY} passHref>
        <Text>Política de Privacidad</Text>
      </Link>
    </TextContainer>
  </Container>
)

/**
 * PropTypes
 */

DrawerMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

/**
 * Styled Components
 */

const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  top: 0;
  padding: 20px;
  transition: transform 0.3s ease-in-out;
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(-100%)')};
  background-color: ${props => props.theme.colors.surface};
  z-index: 99;
`

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  margin-bottom: 30px;
`

const NavBarItem = styled.a`
  padding: 10px 0;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  color: ${props => props.theme.colors.text};
  cursor: pointer;

  @media (min-width: 321px) {
    padding: 15px 0;
    font-size: 25px;
  }
`

const SocialMediaContainer = styled.div`
  margin: 20px 0;
`

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
`

const Text = styled.a`
  display: flex;
  padding: 0;
  color: ${props => props.theme.colors.secondaryText};
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;
  padding: 5px 0;

  &::after {
    content: ' - ';
    white-space: pre;
  }

  &:last-child::after {
    content: '';
  }
`

/**
 * Exports
 */

export default DrawerMenu
