import React from 'react'
import { config } from 'react-awesome-styled-grid'
import {
  faFacebookF,
  faInstagram,
  faSoundcloud,
  faSpotify,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import styled from 'styled-components'

/**
 * SocialMediaStrip
 */

const SocialMediaStrip = ({ inverted }) => {
  const iconColor = !inverted ? 'black' : 'white'

  return (
    <Container>
      <IconContainer
        inverted={inverted}
        aria-label="Facebook"
        href="http://www.facebook.com/djmagla"
        rel="noopener noreferrer"
        target="_blank"
      >
        <Icon color={iconColor} icon={faFacebookF} />
      </IconContainer>
      <IconContainer
        inverted={inverted}
        aria-label="Instagram"
        href="http://www.instagram.com/djmagla"
        rel="noopener noreferrer"
        target="_blank"
      >
        <Icon color={iconColor} icon={faInstagram} />
      </IconContainer>
      <IconContainer
        inverted={inverted}
        aria-label="Twitter"
        href="http://www.twitter.com/djmagla"
        rel="noopener noreferrer"
        target="_blank"
      >
        <Icon color={iconColor} icon={faTwitter} />
      </IconContainer>
      <IconContainer
        inverted={inverted}
        aria-label="Spotify"
        href="http://open.spotify.com/playlist/32i342Ub6raTbkjmqE7BiH"
        rel="noopener noreferrer"
        target="_blank"
      >
        <Icon color={iconColor} icon={faSpotify} />
      </IconContainer>
      <IconContainer
        inverted={inverted}
        aria-label="YouTube"
        href="http://www.youtube.com/djmaglatinoamericatv"
        rel="noopener noreferrer"
        target="_blank"
      >
        <Icon color={iconColor} icon={faYoutube} />
      </IconContainer>
      <IconContainer
        inverted={inverted}
        aria-label="Soundcloud"
        href="http://www.soundcloud.com/djmagla"
        rel="noopener noreferrer"
        target="_blank"
      >
        <Icon color={iconColor} icon={faSoundcloud} />
      </IconContainer>
    </Container>
  )
}

/**
 * PropTypes
 */

SocialMediaStrip.propTypes = {
  inverted: PropTypes.bool,
}

SocialMediaStrip.defaultProps = {
  inverted: false,
}

/**
 * StyledComponents
 */

const Container = styled.div`
  display: flex;
  flex-direction: row;
`

const IconContainer = styled.a`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background-color: ${props => (!props.inverted ? 'white' : 'black')};
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }

  ${props => config(props).media.lg`
    width: 47px;
    height: 47px;
    margin-right: 20px;
  `}
`

const Icon = styled(FontAwesomeIcon)`
  width: 1.3em !important;
  height: 1.3em !important;

  ${props => config(props).media.lg`
    width: 1.5em !important;
    height: 1.5em !important;
  `}
`

/**
 * Exports
 */

export default SocialMediaStrip
