import React from 'react'
import { config } from 'react-awesome-styled-grid'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Footer from '~/components/Footer'
import Header from '~/components/Header'

/**
 * Layout
 */

const Layout = ({ children, isMobile }) => {
  return (
    <>
      <Header isMobile={isMobile} />
      <Wrapper>{children}</Wrapper>
      <Footer />
    </>
  )
}

/**
 * PropTypes
 */

Layout.propTypes = {
  isMobile: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

/**
 * Styled Components
 */

const Wrapper = styled.div`
  margin-top: ${props => props.theme.extras.mobileHeaderHeight};
  min-height: calc(
    100vh - ${props => props.theme.extras.mobileHeaderHeight} - ${props => props.theme.extras.mobileFooterHeight}
  );

  ${props => config(props).media.lg`
    min-height: calc(
      100vh - ${props.theme.extras.desktopHeaderHeight} - ${props.theme.extras.desktopFooterHeight}
    );
    margin-top: 77px;
  `}
`

/**
 * Exports
 */

export default Layout
