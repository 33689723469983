import React from 'react'
import { config } from 'react-awesome-styled-grid'
import Link from 'next/link'
import styled from 'styled-components'

import SocialMediaStrip from '~/components/SocialMediaStrip'
import { Routes } from '~/helpers/constants'

/**
 * Footer
 */

const Footer = () => (
  <Container>
    <SocialMediaStrip />
    <TextContainer>
      <Link href={Routes.ABOUT} passHref>
        <Text>Quiénes Somos</Text>
      </Link>
      <Link href={Routes.ADVERTISING} passHref>
        <Text>Publicidad</Text>
      </Link>
      <Link href={Routes.JOBS} passHref>
        <Text>Trabajos</Text>
      </Link>
      <Link href={Routes.TERMS} passHref>
        <Text>Términos y condiciones</Text>
      </Link>
      <Link href={Routes.PRIVACY_POLICY} passHref>
        <Text>Política de Privacidad</Text>
      </Link>
    </TextContainer>
  </Container>
)

/**
 * StyledComponents
 */

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${props => props.theme.extras.mobileFooterHeight};
  background-color: ${props => props.theme.colors.background};

  ${props => config(props).media.lg`
    height: ${props.theme.extras.desktopFooterHeight};
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
  `}
`

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 30px 10px 0;

  ${props => config(props).media.lg`
    margin: 30px 0 0;
    flex-direction: row;
    margin: 0;
  `}
`

const Text = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px;
  height: 48px;
  padding: 0;
  color: white;
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;

  &:last-child {
    margin-right: 0;
  }

  ${props => config(props).media.lg`
    font-size: 14px;
  `}

  ${props => config(props).media.xl`
    margin: 0 30px 0 0;
    font-size: 16px;
  `}
`

/**
 * Exports
 */

export default Footer
