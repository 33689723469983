import React, { useCallback, useState } from 'react'
import { config } from 'react-awesome-styled-grid'
import Media from 'react-media'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Routes } from '~/helpers/constants'
import BurgerButton from './BurgerButton'
import DrawerMenu from './DrawerMenu'
import SearchOverlay from './SearchOverlay'

/**
 * Header
 */

const Header = ({ isMobile }) => {
  const [isMenuOpen, setMenuOpen] = useState(false)
  const [isSearchOpen, setSearchOpen] = useState(false)
  const handleOpenMenu = useCallback(() => setMenuOpen(true), [])
  const handleCloseMenu = useCallback(() => setMenuOpen(false), [])
  const handleOpenSearch = useCallback(() => setSearchOpen(true), [])
  const handleCloseSearch = useCallback(() => setSearchOpen(false), [])

  return (
    <Media
      queries={{
        small: '(max-width: 992px)',
      }}
      defaultMatches={{ small: isMobile }}
    >
      {matches => (
        <>
          <Container>
            {!matches.small && (
              <>
                <LeftSide>
                  <Link href={Routes.HOME}>
                    <Logo alt="Logo" src="/images/logo.png" />
                  </Link>
                  <NavBar>
                    <Link href={`${Routes.CATEGORY}/[category]`} as={`${Routes.CATEGORY}/noticias`} passHref>
                      <NavBarItem>Noticias</NavBarItem>
                    </Link>
                    <Link href={`${Routes.CATEGORY}/[category]`} as={`${Routes.CATEGORY}/musica`} passHref>
                      <NavBarItem>Música</NavBarItem>
                    </Link>
                    <Link href={`${Routes.CATEGORY}/[category]`} as={`${Routes.CATEGORY}/articulos`} passHref>
                      <NavBarItem>Artículos</NavBarItem>
                    </Link>
                    <Link href={`/${Routes.CATEGORY}/[category]`} as={`${Routes.CATEGORY}/tecnologia`} passHref>
                      <NavBarItem>Tecnología</NavBarItem>
                    </Link>
                    <Link href={Routes.TOP_100} passHref>
                      <NavBarItem>Top 100</NavBarItem>
                    </Link>
                    <NavBarItem href="https://www.otherpeople.club" target="_blank">Merch</NavBarItem>
                    <NavBarItem href="https://issuu.com/djmagla" target="_blank">Revista</NavBarItem>
                  </NavBar>
                </LeftSide>
                <SearchButton aria-label="Buscar" onClick={handleOpenSearch}>
                  <FontAwesomeIcon icon={faSearch} />
                </SearchButton>
              </>
            )}
            {matches.small && (
              <MobileNavBar>
                <BurgerButton onClick={handleOpenMenu} />
                <Link href="/">
                  <Logo alt="Logo" src="/images/logo.png" />
                </Link>
                <SearchButton aria-label="Buscar" onClick={handleOpenSearch}>
                  <FontAwesomeIcon size="2x" icon={faSearch} />
                </SearchButton>
              </MobileNavBar>
            )}
          </Container>
          {matches.small && <DrawerMenu isOpen={isMenuOpen} onClose={handleCloseMenu} />}
          <SearchOverlay isMobile={isMobile} isOpen={isSearchOpen} onClose={handleCloseSearch} />
        </>
      )}
    </Media>
  )
}

/**
 * PropTypes
 */

Header.propTypes = {
  isMobile: PropTypes.bool.isRequired,
}

/**
 * StyledComponents
 */

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${props => props.theme.extras.mobileHeaderHeight};
  padding: 20px;
  box-shadow: 2px -8px 12px #888;
  background-color: ${props => props.theme.colors.surface};
  z-index: 98;

  ${props => config(props).media.lg`
    height: ${props.theme.extras.desktopHeaderHeight};
  `}
`

const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
`

const NavBar = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const NavBarItem = styled.a`
  font-weight: bolder;
  font-size: 16px;
  text-decoration: none;
  color: ${props => props.theme.colors.text};
  cursor: pointer;
  margin: 0 30px;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 3px;
    opacity: 0;
    background: ${({ theme }) => theme.colors.text};
    transition: opacity 0.1s;
  }

  &:hover::after {
    opacity: 1;
  }

  ${props => config(props).media.md`
    font-size: 18px;
    margin: 0 20px;
  `}

  ${props => config(props).media.xl`
    font-size: 20px;
    margin: 0 25px;
  `}
`

const SearchButton = styled.button`
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;

  svg {
    width: 25px !important;
    height: 25px !important;
  }

  ${props => config(props).media.lg`
    margin-right: 20px;
  `}
`

const Logo = styled.img`
  width: 75px;
  cursor: pointer;
  object-fit: contain;

  ${props => config(props).media.lg`
    width: 100px;
    margin-right: 20px;
  `}
`

const MobileNavBar = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
`

export default Header
