export const DEFAULT_CUSTOM_CATEGORY_SLUG = 'articulos'
export const INITIAL_RECENT_POSTS_OFFSET = 0

export const CategoryIds = Object.freeze({
  noticias: 431,
  articulos: 432,
  tecnologia: 433,
  musica: process.env.NEXT_PUBLIC_CATEGORY_MUSIC, // TODO: Extract these categories from an API call
  equipamiento: 648,
})

export const ResultPageTypes = Object.freeze({
  author: 'author',
  category: 'category',
  tag: 'tag',
  search: 'search',
})

export const Routes = Object.freeze({
  HOME: '/',
  TOP_100: '/top-100',
  CATEGORY: '/categoria',
  SEARCH: '/search',
  AUTHOR: '/autor',
  TAGS: '/tags',
  PRIVACY_POLICY: '/politica-de-privacidad',
  ADVERTISING: '/publicidad',
  ABOUT: '/quienes-somos',
  TERMS: '/terminos-y-condiciones',
  JOBS: '/trabajos',
})
