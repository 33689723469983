import React, { useCallback, useEffect, useRef, useState } from 'react'
import { config } from 'react-awesome-styled-grid'
import { faAsterisk, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { debounce, isEmpty, map, size } from 'lodash'
import Link from 'next/link'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { BASE_PAGE, searchPostsByTermsWithTotal } from '~/api'
import { Routes } from '~/helpers/constants'
import FeedItem from './FeedItem'

/**
 * SearchOverlay
 */

const SearchOverlay = ({ isOpen = false, onClose, isMobile }) => {
  const [query, setQuery] = useState('')
  const [results, setResults] = useState([])
  const [total, setTotal] = useState()
  const [loading, setLoading] = useState(false)
  const inputSearchRef = useRef()

  const shouldShowEmptyMessage = !isEmpty(query) && isEmpty(results) && !loading
  const shouldShowMoreButton = size(results) >= BASE_PAGE && !loading
  const shouldShowResults = !loading && !isEmpty(results)

  const focus = useCallback(() => {
    if (inputSearchRef.current) {
      inputSearchRef.current.focus()
    }
  }, [])

  useEffect(() => {
    if (isOpen) {
      focus()
    }
  }, [focus, isOpen])

  const handleSubmit = useCallback(async value => {
    setLoading(true)

    const { posts, total: postTotal } = await searchPostsByTermsWithTotal(value)

    setResults(posts)
    setTotal(postTotal)
    setLoading(false)
    setQuery(value)
  }, [])

  const debouncedSubmit = useCallback(debounce(handleSubmit, 300), [])

  const handleChange = useCallback(
    e => {
      const value = e.target.value
      setQuery(value)

      if (value.length > 2) {
        debouncedSubmit(value)
      }
    },
    [debouncedSubmit]
  )

  const handleResultPress = useCallback(() => {
    onClose()
  }, [onClose])

  const handleViewMorePress = useCallback(() => {
    onClose()
  }, [onClose])

  const handleClearText = useCallback(() => {
    inputSearchRef.current.value = ''
    setQuery('')
    setResults([])
    setTotal('')
    focus()
  }, [focus])

  const resultsMessageText =
    total > 1 ? `Encontramos ${total} resultados para ` : `Encontramos ${total} resultado para `

  return (
    <Container isOpen={isOpen}>
      <CloseButton aria-label="Cerrar" onClick={onClose}>
        <FontAwesomeIcon size="2x" icon={faTimes} />
      </CloseButton>
      <Form>
        <SearchContainer>
          <SearchInput
            ref={inputSearchRef}
            type="text"
            name="query"
            placeholder="Buscar..."
            aria-label="Buscar"
            onChange={handleChange}
          />
          {!isEmpty(query) && <ClearTextAccessory onClick={handleClearText}>LIMPIAR</ClearTextAccessory>}
        </SearchContainer>
      </Form>
      <ResultsContainer>
        {loading && (
          <IconContainer>
            <FontAwesomeIcon size="2x" spin icon={faAsterisk} />
          </IconContainer>
        )}

        {shouldShowEmptyMessage && <Message>No hay resultados</Message>}

        {shouldShowResults && (
          <>
            {map(results, item => (
              <FeedItem isMobile={isMobile} key={item.id} item={item} onPress={handleResultPress} />
            ))}

            <Message>
              {resultsMessageText}

              <SearchTerm>{query}</SearchTerm>
            </Message>
          </>
        )}
      </ResultsContainer>

      {!loading && !isEmpty(results) && shouldShowMoreButton && (
        <Link href={`${Routes.SEARCH}/[term]`} as={`${Routes.SEARCH}/${query}`} passHref>
          <ViewMoreButton onClick={handleViewMorePress}>Ver más resultados</ViewMoreButton>
        </Link>
      )}
    </Container>
  )
}

/**
 * PropTypes
 */

SearchOverlay.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
}

/**
 * Styled Components
 */

const Container = styled.div`
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  top: 0;
  right: 0;
  padding: 20px;
  transition: transform 0.3s ease-in-out;
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(100%)')};
  background-color: ${props => props.theme.colors.surface};
  z-index: 100;
  overflow-y: scroll;
  box-shadow: 0px 0px 6px #777;

  ${props => config(props).media.lg`
    width: 50%;
  `}
`

const Form = styled.div`
  right: 60px;
  left: 15px;
  top: 5px;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${props => config(props).media.lg`
    top: 15px;
    right: 75px;
    left: 15px;
  `}
`

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  width: 100%;
  margin-bottom: 30px;
  border-bottom: 1px solid ${props => props.theme.colors.text};
`

const SearchInput = styled.input`
  flex: 1;
  border-radius: 2px;
  padding: 5px;
  font-size: 16px;
  border: none;
  border-radius: 0;
  box-shadow: none;
  height: 40px;
  background-color: ${props => props.theme.colors.surface};

  &:focus {
    outline: none;
  }
`

const ClearTextAccessory = styled.button`
  padding: 4px;
  border: none;
  box-shadow: none;
  background-color: transparent;
  color: ${props => props.theme.colors.metaText};
  font-size: 10px;
  font-weight: 200;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`

const Message = styled.h3`
  margin-top: 50px;
  color: ${props => props.theme.colors.metaText};
`

const SearchTerm = styled.span`
  color: ${props => props.theme.colors.secondaryText};
`

const IconContainer = styled.div`
  margin-top: 10px;

  ${props => config(props).media.lg`
    margin-top: 20px;
    margin-bottom: 5px;
  `}
`

const ResultsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 42px;
`

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 25px;
  background-color: transparent;
  border: none;
  padding: 0;
  margin-bottom: 30px;
  z-index: 101;
  cursor: pointer;

  ${props => config(props).media.lg`
    top: 25px;
    right: 40px;
  `}
`

const ViewMoreButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 12px 24px;
  background-color: ${props => (!props.fetching ? props.theme.colors.primary : props.theme.colors.primaryTinted)};
  color: ${props => props.theme.colors.textOverImage};
  font-size: 20px;
  font-weight: 700;
  box-shadow: none;
  border: none;
  border-radius: 5px;
  cursor: ${props => (!props.fetching ? 'pointer' : 'progress')};

  &:hover {
    background-color: ${props => props.theme.colors.primaryTinted};
  }

  ${props => config(props).media.md`
    width: 300px;
  `}
`

/**
 * Exports
 */

export default SearchOverlay
