import React from 'react'
import { config } from 'react-awesome-styled-grid'
import Link from 'next/link'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Routes } from '~/helpers/constants'

/**
 * Category
 */

const Category = ({ category, size }) => {
  const { name, slug } = category

  return (
    <CategoryContainer>
      <Link href={`${Routes.CATEGORY}/${slug}`} passHref>
        <a>
          <CategoryItem size={size}>{name}</CategoryItem>
        </a>
      </Link>
    </CategoryContainer>
  )
}

/**
 * PropTypes
 */

Category.propTypes = {
  category: PropTypes.object.isRequired,
  size: PropTypes.string,
}

Category.defaultProps = {
  size: null,
}

/**
 * Styled Components
 */

const CategoryContainer = styled.div`
  padding: 0;
  margin: 0;
  z-index: 1;
`

const CategoryItem = styled.div`
  display: inline-block;
  max-width: 100%;
  padding: 4px 10px;
  padding-top: ${props => (props.size === 'big' ? '4px' : '6px')};
  box-sizing: border-box;
  margin-bottom: 5px;
  border-radius: 1px;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.textOverImage};
  font-weight: 900;
  text-transform: uppercase;
  font-size: 14px;
  opacity: 1;

  ${props => config(props).media.lg`
    font-size: ${props.size === 'big' ? '20px' : null};
  `}
`

/**
 * Exports
 */

export default Category
