/* eslint-disable no-underscore-dangle */
import he from 'he'
import { get, isNil, map, split } from 'lodash'

export const stripHtmlFromString = string => {
  if (isNil(string)) {
    return null
  }

  return string.replace(/<[^>]*>?/gm, '')
}

const parseContent = content => {
  if (isNil(content)) {
    return null
  }

  // Excerpt is included in post
  const parsedContent = split(content, '<!--more-->')

  if (isNil(parsedContent[1])) {
    return parsedContent[0]
  }

  return parsedContent[1]
}

const decode = string => (!isNil(string) ? he.decode(string) : null)

export const parsePost = ({
  id,
  title: _title,
  _embedded,
  content: _content,
  excerpt: _excerpt,
  date,
  slug: _slug,
  acf,
}) => {
  const categoryName = get(_embedded, '["wp:term"][0][0].name', null)
  const categorySlug = get(_embedded, '["wp:term"][0][0].slug', null)

  return {
    id: id || null,
    title: decode(get(_title, 'rendered')),
    images: {
      original: get(_embedded, '["wp:featuredmedia"][0].source_url', '/images/thumb_default.jpg'),
      medium: get(
        _embedded,
        '["wp:featuredmedia"][0].media_details.sizes.medium.source_url',
        '/images/thumb_default.jpg'
      ),
    },
    ...(categoryName &&
      categorySlug && {
        category: {
          name: categoryName,
          slug: categorySlug,
        },
      }),
    tags: get(_embedded, '["wp:term"][1]', []),
    content: decode(parseContent(get(_content, 'rendered'))),
    excerpt: decode(stripHtmlFromString(get(_excerpt, 'rendered'))),
    createdAt: date || null,
    author: {
      name: get(_embedded, 'author[0].name', null),
      id: get(_embedded, 'author[0].id', null),
    },
    slug: _slug || null,
    hasFeaturedHero: get(acf, 'has_featured_hero', false),
    passwordProtected: get(_content, 'protected', false),
  }
}

export const parseMultiplePosts = results => {
  const parsedResults = map(results, item => parsePost(item))

  return parsedResults
}
