import { slice } from 'lodash'
import moment from 'moment'
import { UAParser } from 'ua-parser-js'

import { BASE_PAGE } from '~/api'

import 'moment/locale/es'

/**
 * Redefine Locale to capitalize first letter
 */

moment.updateLocale('es', {
  relativeTime: {
    future: 'En %s',
    past: 'Hace %s',
    s: 'unos segundos',
    ss: '%d segundos',
    m: 'un minuto',
    mm: '%d minutos',
    h: 'una hora',
    hh: '%d horas',
    d: 'un día',
    dd: '%d días',
    M: 'un mes',
    MM: '%d meses',
    y: 'un año',
    yy: '%d años',
  },
})

/**
 * Checks if device is mobile from user agent
 */

export const isMobileDeviceType = request => {
  const userAgent = request ? request.headers['user-agent'] : navigator.userAgent
  const parsedUserAgent = new UAParser(userAgent)
  const isMobile = parsedUserAgent.getDevice().type === 'mobile'

  return isMobile
}

/**
 * Obtain the date in long format
 */

export const getLongDate = date =>
  moment(date)
    .locale('es')
    .format('DD [de] MMMM [de] YYYY')

/**
 * Obtains a formatted date with a fromNow window of 1 month
 */

export const getFreshDate = date => {
  const momentDate = moment(date)

  return momentDate.isSame(moment(), 'month') ? momentDate.locale('es').fromNow() : getLongDate(date)
}

/**
 * Obtains a formatted date with a fromNow window of 1 year
 */

export const getStaleDate = date => {
  const momentDate = moment(date)

  return momentDate.isSame(moment(), 'year') ? momentDate.locale('es').fromNow() : getLongDate(date)
}

/**
 * Get a randomized ad height
 */

export const getAdHeight = isMobile => {
  const randomValue = Math.random(0, 1)

  if (isMobile || randomValue > 0.5) {
    return '250px'
  }

  return '600px'
}

/**
 * Slice Posts and return two blocks: the first with 6 and the second with the rest (usually 12-6 = 6)
 */

export const slicePosts = posts => {
  const featuredPosts = slice(posts, 0, BASE_PAGE / 2)
  const recentPosts = slice(posts, BASE_PAGE / 2)

  return { featuredPosts, recentPosts }
}

/**
 * Slice Condensed Posts and return two blocks: the first with 3 and the second with the rest (usually 12-3 = 9)
 */

export const sliceCondensedPosts = posts => {
  const featuredPosts = slice(posts, 0, 3)
  const recentPosts = slice(posts, 3)

  return { featuredPosts, recentPosts }
}
