import { filter, get, isEmpty } from 'lodash'
import moment from 'moment'
import fetch from 'node-fetch'

import { parseMultiplePosts, parsePost } from '~/helpers/api'
import { CategoryIds, DEFAULT_CUSTOM_CATEGORY_SLUG, INITIAL_RECENT_POSTS_OFFSET } from '~/helpers/constants'

/**
 * Constants
 */

const API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL
const API_BASE_ADS_URL = process.env.NEXT_PUBLIC_API_BASE_ADS_URL
const CATEGORY = CategoryIds[DEFAULT_CUSTOM_CATEGORY_SLUG]
export const BASE_PAGE = 12

/**
 * Helpers
 */

export const fetchFeaturedPosts = async () => {
  const response = await fetch(`${API_BASE_URL}/posts?&_embed&per_page=18&sticky=true`)
  const data = await response.json()

  return parseMultiplePosts(data)
}

export const fetchFirstBlockPosts = async (_key, offset = INITIAL_RECENT_POSTS_OFFSET) => {
  const response = await fetch(`${API_BASE_URL}/posts?&_embed&per_page=6&offset=${offset}&sticky=false`)
  const data = await response.json()

  return parseMultiplePosts(data)
}

export const fetchCategoryPosts = async () => {
  const response = await fetch(`${API_BASE_URL}/posts?&_embed&per_page=4&categories=${CATEGORY}`)
  const data = await response.json()

  if (isEmpty(data)) {
    return []
  }

  return parseMultiplePosts(data)
}

export const fetchPostBySlug = async (_key, slug) => {
  const response = await fetch(`${API_BASE_URL}/posts?slug=${slug}&_embed`)
  const data = await response.json()

  if (isEmpty(data)) {
    return {}
  }

  return parsePost(data[0])
}

export const fetchTagsBySlug = async tag => {
  const response = await fetch(`${API_BASE_URL}/tags?search=${tag}&_embed&per_page=${BASE_PAGE}`)
  const data = await response.json()

  return data
}

/**
 * Fetch Recent Posts, from newest to latest, paged by the BASE_PAGE and the offset
 * This function is called directly and from react-query with the same params
 *
 * @param {string} _primaryKey Injected by react-query, the primary key for the query, e.g.:  'infinite-posts'
 * @param {number} tagId Injected by react-query, the tagId, e.g.: 58
 * @param {number} offset Injected by react-query, the offset calculated by the useQuery hook, e.g.: 18
 */

export const fetchRecentPosts = async (_primaryKey, _secondaryKey, offset = INITIAL_RECENT_POSTS_OFFSET) => {
  const response = await fetch(`${API_BASE_URL}/posts?&_embed&per_page=12&offset=${offset}&sticky=false`)
  const data = await response.json()

  return parseMultiplePosts(data)
}

/**
 * Fetch Posts by TagId, from newest to latest, paged by the BASE_PAGE and the offset
 * This function is called directly and by react-query with the same params
 *
 * @param {string} _primaryKey Injected by react-query, the primary key for the query, e.g.:  'infinite-posts'
 * @param {number} tagId Injected by react-query, the tagId, e.g.: 58
 * @param {number} offset Injected by react-query, the offset calculated by the useQuery hook, e.g.: 18
 */

export const fetchPostsByTagId = async (_primaryKey, tagId, offset = 0) => {
  const response = await fetch(`${API_BASE_URL}/posts?tags=${tagId}&_embed&per_page=${BASE_PAGE}&offset=${offset}`)
  const data = await response.json()

  return parseMultiplePosts(data)
}

/**
 * Fetch Posts by CategoryId, from newest to latest, paged by the BASE_PAGE and the offset
 * This function is called directly and from react-query with the same params
 *
 * @param {string} _primaryKey Injected by react-query, the primary key for the query, e.g.:  'infinite-posts'
 * @param {number} categoryId Injected by react-query, the categoryId, e.g.: 58
 * @param {number} offset Injected by react-query, the offset calculated by the useQuery hook, e.g.: 18
 */

export const fetchPostsByCategory = async (_key, categoryId, offset = 0) => {
  const response = await fetch(
    `${API_BASE_URL}/posts?categories=${CategoryIds[categoryId]}&_embed&per_page=${BASE_PAGE}&offset=${offset}`
  )

  const data = await response.json()
  const status = get(data, 'data.status')

  if (status === 400) {
    return []
  }

  return parseMultiplePosts(data)
}

/**
 * Fetch Posts by AuthorId, from newest to latest, paged by the BASE_PAGE and the offset
 * This function is called directly and by react-query with the same params
 *
 * @param {string} _primaryKey Injected by react-query, the primary key for the query, e.g.:  'infinite-posts'
 * @param {number} authorId Injected by react-query, the authorId, e.g.: 2
 * @param {number} offset Injected by react-query, the offset calculated by the useQuery hook, e.g.: 18
 */

export const fetchPostsByAuthorId = async (_key, authorId, offset = 0) => {
  const response = await fetch(`${API_BASE_URL}/posts?author=${authorId}&_embed&per_page=${BASE_PAGE}&offset=${offset}`)
  const data = await response.json()

  return parseMultiplePosts(data)
}

/**
 * Search Posts by Term(s), from newest to latest, paged by the BASE_PAGE and the offset
 * This function is called directly and from react-query with the same params
 *
 * @param {string} _primaryKey Injected by react-query, the primary key for the query, e.g.:  'infinite-posts'
 * @param {number} term Injected by react-query, the term, e.g.: tiesto
 * @param {number} offset Injected by react-query, the offset calculated by the useQuery hook, e.g.: 18
 */

export const searchPostsByTerms = async (_key, term, offset = 0) => {
  const response = await fetch(`${API_BASE_URL}/posts?search=${term}&_embed&per_page=${BASE_PAGE}&offset=${offset}`)
  const data = await response.json()

  return parseMultiplePosts(data)
}

export const searchPostsByTermsWithTotal = async (term, offset = 0) => {
  const response = await fetch(`${API_BASE_URL}/posts?search=${term}&_embed&per_page=${BASE_PAGE}&offset=${offset}`)
  const data = await response.json()
  const total = response.headers.get('x-wp-total')
  const posts = parseMultiplePosts(data)

  return { posts, total }
}

export const fetchPageBySlug = async slug => {
  const response = await fetch(`${API_BASE_URL}/pages?slug=${slug}`)
  const data = await response.json()

  if (isEmpty(data)) {
    return {}
  }

  return parsePost(data[0])
}

export const fetchAdsList = async () => {
  const response = await fetch(`${API_BASE_ADS_URL}/list`)
  const data = await response.json()

  const activeData = filter(
    data,
    item => moment().isBefore(moment(item.fdate)) || item.fdate === '0000-00-00 00:00:00' || item.sdate === item.fdate
  )

  return activeData
}
