import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

/**
 * BurgerButton
 */

const BurgerButton = ({ onClick }) => (
  <Button aria-label="Menú" onClick={onClick}>
    <Line />
    <Line />
    <Line />
  </Button>
)

/**
 * PropTypes
 */

BurgerButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

/**
 * Styled Components
 */

const Button = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 25px;
  height: 25px;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`

const Line = styled.div`
  width: 25px;
  height: 3px;
  background: black;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
  border-radius: 10px;
`

/**
 * Exports
 */

export default BurgerButton
